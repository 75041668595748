
import { defineComponent, PropType } from 'vue';

interface TooltipItem {
  id: string | number,
  color: string,
  value?: number,
  label: string,
  hint?: string,
}

export default defineComponent({
  name: 'LineChartCustomTooltip',

  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array as PropType<TooltipItem[]>,
      default: () => ([]),
    },
  },
});

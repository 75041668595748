/**
 * Реализация основана на https://stackoverflow.com/a/14487422/4825796
 */

const testWhite = (str: string) => {
  const white = new RegExp(/^\s$/);
  return white.test(str.charAt(0));
};

export default (str: string, maxWidth = 50, linesCount = -1) => {
  if (!str.length || !maxWidth) {
    return str;
  }

  const updatedStr = [];
  let strIn = str;
  while (strIn.length > maxWidth) {
    let found = false;

    // Делим строку по первому пробелу в строке
    for (let i = maxWidth - 1; i >= 0; i -= 1) {
      if (testWhite(strIn.charAt(i))) {
        // Проверяем с конца на наличие пробела
        updatedStr.push(strIn.slice(0, i));
        strIn = strIn.slice(i + 1);
        found = true;
        break;
      }
    }
    // Делим строку по максимальной ширине (maxWidth), т.к. слово слишком длинное
    if (!found) {
      updatedStr.push(strIn.slice(0, maxWidth));
      strIn = strIn.slice(maxWidth);
    }
  }

  updatedStr.push(strIn);
  if (linesCount < 0) {
    return updatedStr;
  }

  // Ограничиваем количество строк
  const res = updatedStr.slice(0, linesCount);
  if (updatedStr.length > linesCount) {
    res[res.length - 1] = `${res[res.length - 1].slice(0, maxWidth)}…`;
  }

  return res;
};

/* eslint-disable no-param-reassign */
import { valueOrDefault } from 'chart.js/helpers';
import {
  Chart,
  Plugin,
  Scale,
  CoreScaleOptions,
  ScatterDataPoint,
} from 'chart.js';
import { LineChartDataSet } from '@/components/analytics/charts/types';

interface CustomTooltipItem {
  id: string | number,
  color: string,
  value?: number,
  label: string,
  hint?: number,
}

export interface CustomTooltipData {
  title?: string,
  items: CustomTooltipItem[],
}

export interface LineChartCustomTooltip {
  tooltip: {
    tooltipEl: () => HTMLDivElement | null,
  },
  callbacks: {
    onTooltipDataUpdate: (e: CustomTooltipData) => any,
  },
  settings: {
    lineBorderWidth: number,
  },
}

interface ChartWithCustomTooltip extends Chart<'line'> {
  lineChartCustomTooltip?: {
    cursorX: number | null,
    // Ключ - индекс датасета, значение - интерполированное значение датасета в координате x курсора
    datasetValuesAtCursor: number[],
  }
}

interface LineChartCustomTooltipPluginType extends Plugin<'line'> {
  getOption: (chart: ChartWithCustomTooltip, category: string, name: string) => any,
  getXScale: (chart: ChartWithCustomTooltip) => Scale<CoreScaleOptions> | null,
  getYScale: (chart: ChartWithCustomTooltip) => Scale<CoreScaleOptions>,
  interpolateValues: (chart: ChartWithCustomTooltip) => void,
  hideTooltip: (chart: ChartWithCustomTooltip) => void,
}

const defaultOptions: LineChartCustomTooltip = {
  tooltip: {
    tooltipEl: () => null,
  },
  callbacks: {
    onTooltipDataUpdate() { },
  },
  settings: {
    lineBorderWidth: 3,
  },
};

const LineChartCustomTooltipPlugin: LineChartCustomTooltipPluginType = {

  id: 'lineChartCustomTooltip',

  afterInit(chart: ChartWithCustomTooltip) {
    if (!chart.config.options?.scales?.x) {
      return;
    }

    if (!chart.options.plugins) {
      chart.options.plugins = {};
    }
    if (!chart.options.plugins?.lineChartCustomTooltip) {
      chart.options.plugins.lineChartCustomTooltip = defaultOptions;
    }

    chart.lineChartCustomTooltip = {
      cursorX: null,
      datasetValuesAtCursor: [],
    };
  },

  getOption(chart, category: string, name: string) {
    // @ts-ignore
    return valueOrDefault(chart.options.plugins?.lineChartCustomTooltip?.[category]
      // @ts-ignore
      ? chart.options.plugins.lineChartCustomTooltip[category][name]
      // @ts-ignore
      : undefined, defaultOptions[category][name]);
  },

  getXScale(chart) {
    return chart.data.datasets.length ? chart.scales[chart.getDatasetMeta(0).xAxisID!] : null;
  },
  getYScale(chart) {
    return chart.scales[chart.getDatasetMeta(0).yAxisID!];
  },

  hideTooltip(chart: ChartWithCustomTooltip) {
    const tooltipEl = this.getOption(chart, 'tooltip', 'tooltipEl');
    if (!tooltipEl) {
      return;
    }
    tooltipEl.style.opacity = 0;
  },

  afterEvent(chart: ChartWithCustomTooltip, event: any) {
    if (!event.inChartArea) {
      this.hideTooltip(chart);
      return;
    }

    this.interpolateValues(chart);

    const e = event.event;
    const xScale = this.getXScale(chart);

    if (!xScale) {
      return;
    }

    chart.lineChartCustomTooltip!.cursorX = e.x;

    let isTooltipVisible = false;
    let tooltipData = null;

    // Точки графика под курсором
    const points = chart.getElementsAtEventForMode(e, 'nearest', { axis: 'xy', intersect: true }, true);
    if (points.length) {
      isTooltipVisible = true;

      const label = chart.scales.x.getLabelForValue(points[0].index);
      const title = Array.isArray(label) ? label.join(' ') : label;
      tooltipData = {
        title,
        items: points.map((point) => ({
          id: `${point.datasetIndex}-${point.index}`,
          color: chart.data.datasets[point.datasetIndex].backgroundColor,
          value: (chart.data?.datasets?.[point.datasetIndex]?.data[point.index] as ScatterDataPoint)?.y,
          label: chart.data.datasets[point.datasetIndex].label,
          // @ts-ignore
          hint: chart.data.datasets[point.datasetIndex].data?.[point.index]?.hint,
        })),
      };
    } else {
      const yScale = chart.scales.y;
      const epsilon = this.getOption(chart, 'settings', 'lineBorderWidth') * 1.25;
      const hoveredDatasets = chart.data.datasets.filter(
        (_dataset, datasetIndex) => Math.abs(
          yScale.getPixelForValue(chart.lineChartCustomTooltip!.datasetValuesAtCursor[datasetIndex]) - e.y,
        ) <= epsilon,
      );
      tooltipData = {
        title: null,
        items: hoveredDatasets.map((dataset) => ({
          id: (dataset as LineChartDataSet).id,
          color: dataset.backgroundColor,
          label: dataset.label,
        })),
      };
      isTooltipVisible = !!hoveredDatasets.length;
    }

    if (tooltipData?.items?.length) {
      this.getOption(chart, 'callbacks', 'onTooltipDataUpdate')(tooltipData);
    }

    const tooltipEl = this.getOption(chart, 'tooltip', 'tooltipEl');
    // Обновляем координаты тултипа
    if (tooltipEl) {
      const TOOLTIP_X_OFFSET = 24;
      const TOOLTIP_Y_OFFSET = 16;
      const tooltipBounds = tooltipEl.getBoundingClientRect();
      let newXPos = 0;
      let newYPos = 0;
      if (e.x + TOOLTIP_X_OFFSET + tooltipBounds.width > chart.width) {
        if (e.x - TOOLTIP_X_OFFSET - tooltipBounds.width < 0) {
          // Тултип - под курсором
          newXPos = 0;
          newYPos = e.y + TOOLTIP_Y_OFFSET;
        } else {
          // Тултип - слева от курсора
          newXPos = e.x - TOOLTIP_X_OFFSET - tooltipBounds.width;
          newYPos = Math.max(e.y - TOOLTIP_Y_OFFSET, 0);
        }
      } else {
        // Тултип - справа от курсора
        newXPos = e.x + TOOLTIP_X_OFFSET;
        newYPos = Math.max(e.y - TOOLTIP_Y_OFFSET, 0);
      }

      tooltipEl.style.left = `${newXPos}px`;
      tooltipEl.style.top = `${newYPos}px`;
      tooltipEl.style.opacity = isTooltipVisible ? '1' : '0';
    }
  },

  // Рассчитать значения графиков в текущей координате x курсора
  interpolateValues(chart: ChartWithCustomTooltip) {
    chart.data.datasets.forEach((dataset, chartIndex) => {
      if (typeof chart.lineChartCustomTooltip?.cursorX !== 'number') {
        return;
      }
      const xScale = chart.scales[chart.getDatasetMeta(chartIndex).xAxisID!];
      // Значение тика, соответствущего значению x в месте курсора
      // (getValueForPixel не возвращает значения между тиками)
      const xValue = xScale.getValueForPixel(chart.lineChartCustomTooltip.cursorX);
      if (typeof xValue !== 'number') {
        return;
      }
      const data = dataset.data as ScatterDataPoint[];

      // Индекс тика, равного или большего значения x
      // с непустым значением графика (в этой точке у графика указано значение)
      const rightIndex = data.findIndex((o) => o.x >= xValue && typeof o.y === 'number');
      if (rightIndex === -1) {
        return;
      }

      // Индекс тика с непустым значением графика слева от значения x
      // @ts-ignore
      const leftIndex = data.findLastIndex((item, i) => i < rightIndex && typeof item.y === 'number');

      const index = leftIndex >= 0 && chart.lineChartCustomTooltip.cursorX < xScale.getPixelForValue(data[rightIndex].x)
        ? leftIndex
        : rightIndex;

      const nextPointIndex = data.findIndex((item, i) => i > index && typeof item.y === 'number');

      // Значение x в позиции курсора
      let realXValue = 0;
      if (chart.lineChartCustomTooltip?.cursorX && nextPointIndex >= 0 && data[index]) {
        realXValue = ((chart.lineChartCustomTooltip.cursorX - xScale.getPixelForValue(data[index].x))
          / (xScale.getPixelForValue(data[nextPointIndex].x) - xScale.getPixelForValue(data[index].x)))
          * (data[nextPointIndex].x - data[index].x) + data[index].x;
      }

      const leftPoint = data[index];
      const rightPoint = nextPointIndex > 0 ? data[nextPointIndex] : null;

      if (leftPoint && rightPoint) {
        const slope = (rightPoint.y - leftPoint.y) / (rightPoint.x - leftPoint.x);
        const value = leftPoint.y + (realXValue - leftPoint.x) * slope;
        chart.lineChartCustomTooltip.datasetValuesAtCursor[chartIndex] = value;
      } else {
        chart.lineChartCustomTooltip.datasetValuesAtCursor[chartIndex] = NaN;
      }
    });
  },
};

export default LineChartCustomTooltipPlugin;

import { Chart } from 'chart.js';

/**
 * Рисует ось x обычной прямой, чтобы перекрыть прерывистую
 */
export default (tickLength: number = 32) => ({
  id: 'x-axis-redraw',
  beforeDraw(chart: Chart) {
    const { ctx } = chart;
    const xScale = chart.scales.x;
    const yScale = chart.scales.y;
    ctx.strokeStyle = '#EBECED';
    ctx.beginPath();
    ctx.moveTo(xScale.left - 5, yScale.bottom);
    ctx.lineTo(xScale.right + tickLength, yScale.bottom);
    ctx.stroke();
    return true;
  },
});

import { Chart } from 'chart.js';
import Vue from 'vue';

export default (context: Vue, fieldName: string) => ({
  id: 'chart-legend',
  afterUpdate(chart: Chart) {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    context[fieldName] = chart.options.plugins.legend.labels.generateLabels(chart);
  },
});


import Vue, { PropType } from 'vue';
import { LegendItem, Chart } from 'chart.js';

export default Vue.extend({
  name: 'ChartLegend',

  props: {
    chart: {
      type: Object as PropType<Chart | null>,
      default: null,
    },
    items: {
      type: Array as PropType<LegendItem[]>,
      default: () => [],
    },
  },

  methods: {
    onItemClick(item: LegendItem) {
      if (!this.chart) {
        return;
      }

      const { type } = this.chart.config;
      if (type === 'pie' || type === 'doughnut') {
        this.chart.toggleDataVisibility(item.datasetIndex);
      } else {
        this.chart.setDatasetVisibility(item.datasetIndex, !this.chart.isDatasetVisible(item.datasetIndex));
      }

      this.chart.update();
    },
  },
});

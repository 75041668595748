
import Vue from 'vue';

export default Vue.extend({
  name: 'ChartScrollButtons',

  props: {
    leftButtonDisabled: {
      type: Boolean,
      default: false,
    },
    rightButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
});
